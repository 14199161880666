const jobs = [
    {
        id: '1',
        title: 'Изготвяне на Уеб Сайтове',
        description: `Занимавам се с Уеб разработка на сайтове от около 1 година.
        Технологии, които ползвам са:
        Python / Django
        HTML / CSS
        JavaScript
        Услугите, които предлагам включват:
        Изготвяне на уеб сайт
        Адаптивен дизайн за телефон / таблет / лаптоп / компютър
        Поддръжка на вече изградения уеб сайт, като за това се заплаща допълнителна месечна такса
        SEO оптимизация`,
        city: 'София',
        salary: '200',
        salaryType: 'Пълно работно време',
        publisher: 'borisgarkov',
        datePosted: 'March 10, 2022',
        jobCategory: 'ИТ',
        jobType: 'Таланти',
        likes: '10',
        image: '',
    },
    {
        id: '2',
        title: 'Отопление,  и Климатизация',
        description: `Предлагам проектиране, изграждане и поддръжка на ОВК инсталаци.
        Поддръжка на пелетни съоръжения и термопомпи (вода-въздух и вода-вода)
        Изграждане на отоплителни инсталации с колекторно разпределение (подово, радиатори и конвектори)
        Климатици и климатични инсталации с (Daikin, Mitshubishi, Gree, Cairox и Tangra)
        Изработване на всякакъв вид смукателна и нагнетателна вентилация.`,
        city: 'София',
        salary: '123',
        salaryType: 'Part-time',
        publisher: 'Венцислав',
        datePosted: 'Feb. 14, 2022',
        jobCategory: 'Монтажни Дейности',
        jobType: 'Таланти',
        likes: '11',
        image: '',
    },
    {
        id: '3',
        title: 'Работа Работа Работа',
        description: `Предлагам проектиране, изграждане и поддръжка на ОВК инсталаци.
        Поддръжка на пелетни съоръжения и термопомпи (вода-въздух и вода-вода)
        Изграждане на отоплителни инсталации с колекторно разпределение (подово, радиатори и конвектори)
        Климатици и климатични инсталации с (Daikin, Mitshubishi, Gree, Cairox и Tangra)
        Изработване на всякакъв вид смукателна и нагнетателна вентилация.`,
        city: 'София',
        salary: '123',
        salaryType: 'Part-time',
        publisher: 'Венцислав',
        datePosted: 'Feb. 14, 2022',
        jobCategory: 'Монтажни Дейности',
        jobType: 'Таланти',
        likes: '11',
        image: '',
    },
    {
        id: '4',
        title: 'Отопление, Вентилаци и Климатизация',
        description: `Предлагам проектиране, изграждане и поддръжка на ОВК инсталаци.
        Поддръжка на пелетни съоръжения и термопомпи (вода-въздух и вода-вода)
        Изграждане на отоплителни инсталации с колекторно разпределение (подово, радиатори и конвектори)
        Климатици и климатични инсталации с (Daikin, Mitshubishi, Gree, Cairox и Tangra)
        Изработване на всякакъв вид смукателна и нагнетателна вентилация.
        Предлагам проектиране, изграждане и поддръжка на ОВК инсталаци.
        Поддръжка на пелетни съоръжения и термопомпи (вода-въздух и вода-вода)
        Изграждане на отоплителни инсталации с колекторно разпределение (подово, радиатори и конвектори)
        Климатици и климатични инсталации с (Daikin, Mitshubishi, Gree, Cairox и Tangra)
        Изработване на всякакъв вид смукателна и нагнетателна вентилация.Предлагам проектиране, изграждане и поддръжка на ОВК инсталаци.
        Поддръжка на пелетни съоръжения и термопомпи (вода-въздух и вода-вода)
        Изграждане на отоплителни инсталации с колекторно разпределение (подово, радиатори и конвектори)
        Климатици и климатични инсталации с (Daikin, Mitshubishi, Gree, Cairox и Tangra)
        Изработване на всякакъв вид смукателна и нагнетателна вентилация.Предлагам проектиране, изграждане и поддръжка на ОВК инсталаци.
        Поддръжка на пелетни съоръжения и термопомпи (вода-въздух и вода-вода)
        Изграждане на отоплителни инсталации с колекторно разпределение (подово, радиатори и конвектори)
        Климатици и климатични инсталации с (Daikin, Mitshubishi, Gree, Cairox и Tangra)
        Изработване на всякакъв вид смукателна и нагнетателна вентилация.ни инсталации с колекторно разпределение (подово, радиатори и конвектори)
        Климатици и климатични инсталации с (Daikin, Mitshubishi, Gree, Cairox и Tangra)
        Изработване на всякакъв вид смукателна и нагнетателна вентилация.Предлагам проектиране, изграждане и поддръжка на ОВК инсталаци.
        Поддръжка на пелетни съоръжения и термопомпи (вода-въздух и вода-вода)
        Изграждане на отоплителни инсталации с колекторно разпределение (подово, радиатори и конвектори)
        Климатици и климатични инсталации с (Daikin, Mitshubishi, Gree, Cairox и Tangra)
        Изработване на всякакъв вид смукателна и нагнетателна вентилация.`,
        city: 'София',
        salary: '123',
        salaryType: 'Part-time',
        publisher: 'Венцислав',
        datePosted: 'Feb. 14, 2022',
        jobCategory: 'Монтажни Дейности',
        jobType: 'Таланти',
        likes: '11',
        image: '',
    },
    {
        id: '5',
        title: 'Отопление, Вентилаци и Климатизация',
        description: `Предлагам проектиране, `,
        city: 'София',
        salary: '123',
        salaryType: 'Part-time',
        publisher: 'Венцислав',
        datePosted: 'Feb. 14, 2022',
        jobCategory: 'Монтажни Дейности',
        jobType: 'Таланти',
        likes: '11',
        image: '',
    },
    {
        id: '6',
        title: 'Отопление, Вентилаци и Климатизация',
        description: `Предлагам проектиране, `,
        city: 'София',
        salary: '123',
        salaryType: 'Part-time',
        publisher: 'Венцислав',
        datePosted: 'Feb. 14, 2022',
        jobCategory: 'Монтажни Дейности',
        jobType: 'Таланти',
        likes: '11',
        image: '',
    },
    {
        id: '7',
        title: 'Отопление, Вентилаци и Климатизация',
        description: `Предлагам проектиране, `,
        city: 'София',
        salary: '123',
        salaryType: 'Part-time',
        publisher: 'Венцислав',
        datePosted: 'Feb. 14, 2022',
        jobCategory: 'Монтажни Дейности',
        jobType: 'Таланти',
        likes: '11',
        image: '',
    },
    {
        id: '8',
        title: 'Отопление, Вентилаци и Климатизация',
        description: `Предлагам проектиране, `,
        city: 'София',
        salary: '123',
        salaryType: 'Part-time',
        publisher: 'Венцислав',
        datePosted: 'Feb. 14, 2022',
        jobCategory: 'Монтажни Дейности',
        jobType: 'Таланти',
        likes: '11',
        image: '',
    }
];

export { jobs };